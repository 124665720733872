<template>
  <div style="min-height: calc(100vh - 130px);">

    <table-template :treeProps="{children: 'list',hasChildren: 'hasChildren'}" :table_data="table_data">
      <template slot-scope="scope">
        <!-- <el-link :underline="false" type="primary" @click="showReply(scope.scope.row, scope.$index)" style="margin-right:10px">回复</el-link> -->
        <el-link :underline="false" type="danger">删除</el-link>
      </template>
    </table-template>
    <!-- <base-pageination align="right" :pageinfo="table_data.pageinfo" @handlePageClick="handlePageClick"></base-pageination> -->
    <!-- <el-dialog title="回复留言" :visible.sync="isshowReply" width="30%" :before-close="handleClose">
      <el-form>
        <el-form-item label="用户留言：">
          {{replyObj.content}}
        </el-form-item>
        <el-form-item label="留言回复：">
          <el-input v-model="reply.content" type="textarea" :rows="5" placeholder="请输入要回复的内容"></el-input>
        </el-form-item>
        <div>
          <el-button type="primary" @click="subRely()">提交</el-button>
        </div>
      </el-form>
    </el-dialog> -->
  </div>
</template>

<script>
import BasePageination from '../components/BasePageination.vue'
import ListConditionForm from '../components/ListConditionForm.vue'
import TableTemplate from '../components/TableTemplate.vue'
import {
  getUserList
} from '@/api/user.js'
export default {
  components: { ListConditionForm, TableTemplate, BasePageination },
  data () {
    return {
      table_data: {
        tableConfig: [
          {key: 'username', name: '用户名'},
          {key: 'createtime', name: '创建时间'},
          // {key: 'createTime', name: '发布时间'},
        ],
        data: [],
        pageinfo: { currentpage:1, pagesize: 10, total: 0}
      },
      isshowReply: false,
      replyObj: null,
      reply: {
        content: '',
        username: localStorage.getItem('username'),
        pid: ''
      },
      platformtype:1
    }
  },
  mounted () {
    if (localStorage.getItem('username') == 'dingyuan') this.platformtype = 2
      else if (localStorage.getItem('username') == 'admin') this.platformtype = 1
    this.handlePageClick(1)
  },
  methods: {
    fetchData () {
      getUserList({platformtype: this.platformtype}).then(res => {
        const {code, data, page} = res
        if (code === 10000) {
          this.table_data.data = data
          this.table_data.pageinfo = page
        }
      })
    },
    handlePageClick (page) {
      this.table_data.pageinfo.currentpage = page
      this.fetchData()
    },
    showReply (item) {
      this.replyObj = item
      this.reply.pid = item.id
      this.isshowReply = true
    },
    handleClose () {}
  }
}
</script>

<style>

</style>